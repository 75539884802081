import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Homepage: React.FC = () => (
  <Layout>
    <SEO title="About" />
    <h3 className="text-center">About VoiceForward.org</h3>

    <p>Think of it as the many-to-one email tool.</p>
    <p>
      When grassroots campaigns need to get the attention of a political
      official or organization, they can mount a campaign to urge interested
      people to send coordinated communications to a target. Voice Forward
      streamlines this process by allowing the creation of one email that can be
      shared (Posted / Tweeted / Texted) with a simple link the public can use
      to send an exact copy of the email with their own email account.
    </p>
    <p>
      Development of this tool is still ongoing, but anyone can use it right now
      to share their own campaign!
    </p>
  </Layout>
)

export default Homepage
